export default defineAppConfig({
    ui: {
      button: {
        default: {
          size: 'md',
          color: 'gray',
          variant: 'ghost'
        }
      },
      card: {
        ring: ''
      }
    }
  })
  