import PocketBase from 'pocketbase'

export default defineNuxtPlugin(() => {
  const url = 'https://home-db.bogsynth.com/';//'https://bogsynth.pockethost.io/'
  const pb = new PocketBase(url) 
  return {
    provide: {
      pocketbase: pb,
    },
  }
})