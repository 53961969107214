import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93Um3iRsUHLMMeta } from "/app/pages/projects/[id].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "projects-id",
    path: "/projects/:id()",
    component: () => import("/app/pages/projects/[id].vue")
  }
]